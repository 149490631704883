import { Controller } from "@hotwired/stimulus";
import { get } from "@rails/request.js"
import { routes } from "../routes";

export default class extends Controller {
  static targets = ["centro", "setor"];

  async carregarSetores() {
    [...this.setorTarget.children].forEach(option => option.remove());

    const response = await get(
      routes.centrosPath(this.centroTarget.value), {
      responseKind: "json",
    });

    if (!response.ok) { return; }

    const result = await response.json;
    this.setorTarget.insertAdjacentHTML(
      "beforeend",
      result.map(setor => `<option value="${setor.id}">${setor.nome}</option>`).join()
    );
  }
}
