import { Controller } from "@hotwired/stimulus";
import { get } from "@rails/request.js"
import { routes } from "../routes";

export default class extends Controller {
  static targets = [
    "dataSaida",
    "dataChegada",
    "equipe",
    "veiculos",
    "motoristas"
  ];

  carregarTodos() {
    this.carregar('todos');
  }

  carregarMotoristas() {
    this.carregar('motoristas');
  }

  async carregar(tipo) {
    if (this.validDates()) {
      const response = await get(
        routes.aceitarRequisicoesDisponibilidadesPath({
          data_saida: this.dataSaidaTarget.value,
          data_chegada: this.dataChegadaTarget.value,
          equipe_id: this.equipeTarget.value,
          tipo: tipo,
        }),
        { responseKind: "json" }
      );

      if (!response.ok) { return; }

      const result = await response.json;

      this.reset(tipo);
      if (tipo == 'todos') {
        this.veiculosTarget.insertAdjacentHTML('beforeend', `<option value=""></option>${result.options_for_veiculo}`);
      }
      this.motoristasTarget.insertAdjacentHTML('beforeend', `<option value=""></option>${result.options_for_motorista}`);
    } else {
      this.reset();
    }
  }

  validDates() {
    return this.dataSaidaTarget.value.length === 10 && this.dataChegadaTarget.value.length === 10;
  }

  reset(tipo) {
    if (tipo === 'todos') {
      [...this.veiculosTarget.children].forEach(option => option.remove());
    }

    if (tipo === 'todos' || tipo === 'motoristas') {
      [...this.motoristasTarget.children].forEach(option => option.remove());
    }
  }
};
