import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["hidden", "add", "remove", "table", "template", "city", "state"]

  add(e) {
    e.preventDefault();

    const citySelectedOption = [...this.cityTarget.options].find(option => option.selected);
    if (!citySelectedOption) { return; }

    const cityName = citySelectedOption.innerText;
    const cityId = citySelectedOption.value;
    const stateAcronynm = [...this.stateTarget.options].find(option => option.selected).innerText;

    const newLine = this.templateTarget.innerHTML
      .replace("CIDADE_DESTINO", `${cityName}/${stateAcronynm}`)
      .replace("CIDADE_DESTINO_ID", cityId);

    this.tableTarget.insertAdjacentHTML('beforeend', newLine);
  }

  remove(e) {
    e.preventDefault();

    const tr = e.target.closest('tr');
    tr.parentNode.removeChild(tr);
  }
}
